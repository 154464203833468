.App {
    text-align: center;
  }
  
  img {
    width: 100%; /* Makes the image fill the width of its container */
    height: 220px; /* Sets a fixed height for the images */
    object-fit: cover; /* Ensures the image covers the area without stretching */
    border-radius: 5px; /* Optional: rounded corners for images */
  }
  
  .product-list {
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .product {
    border: 1px solid #ddd;
    padding: 20px; /* Reduced padding for a better layout */
    border-radius: 5px;
    width: 250px; /* Adjusted width to fit the images and text nicely */
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
  }
  
  form {
    position: relative;
    align-items: center;
    top: 100px;
    border: 1px solid #ddd;
    background-color: #f9f9f9; /* Light background color for a soft look */
    border-radius: 8px; /* Rounded corners */
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    padding: 20px; /* Add padding inside the form */
    gap: 15px; /* Slightly larger gap for better spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    font-family: Arial, sans-serif; /* Use a clean font */
  }
  
  form input, form textarea, form select, form button {
    padding: 10px; /* Add padding for inputs */
    border: 1px solid #ccc; /* Define border for inputs */
    border-radius: 5px; /* Rounded corners for inputs */
    font-size: 16px; /* Set font size for inputs */
  }
  
  form button {
    background-color: #007BFF; /* Use a primary color for the button */
    color: white; /* White text color for better contrast */
    border: none; /* Remove button border */
    cursor: pointer; /* Change cursor to pointer for better UX */
    transition: background-color 0.3s ease; /* Smooth hover transition */
  }
  
  form button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  
  input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #218838;
  }

  .producttitle {
    text-align: center; /* Center the text */
    margin: 20px 0; /* Optional: Adds some space above and below the heading */
  }
  .notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    opacity: 0; /* Start invisible */
    transition: opacity 0.5s ease; /* Transition for fading */
  }
  
  .fade-in {
    opacity: 1; /* Fully visible */
  }
  
  .fade-out {
    opacity: 0; /* Fade out */
  }
  
  