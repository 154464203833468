nav
{
    margin-top: 0px;
    top: 0px;
    display: flex;
    padding: 20px;
    background-color:  rgb(4, 3, 26);
    border-bottom: 1px solid rgb(225, 225, 225);
    position: sticky;
    z-index: 99;
    justify-content: end;
}

.menu ul
{
    color: red;
}
nav a
{
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin: 0 20px;
    padding: 10px 20px;
    transition: color 0.3s ease; 
    right: 200px;
    
}
.home.active,.about.active, .project.active,
nav a:hover
{
    color: rgb(100, 101, 102);
    
}
.Logo{
    position: absolute;
    color: black;
    padding: 0px 20px;
    text-decoration: none;
    justify-content: center;
    z-index: 100;
    left: 10px;
}

@media screen and (max-width:767px)
{
.menu
{
  display: none;
   
}
.burger-menu {
    position: relative;
    align-self: self-start;
  }
  
  .burger-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .burger-icon .bar {
    width: 25px;
    height: 1px;
    margin: 4px 0;
    background-color: #333;
    transition: 0.4s;
  }
  
  .burger-icon.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-7px, 5px);
  }
  
  .burger-icon.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .burger-icon.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-7px, -5px);
  }
  .menu.open
  {
    opacity: 1;
    display: block;
  }
  nav a
  {
    display: block;
  }

  .nav
  {
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
